import { modal } from 'components'

const imgClick = {
  el: {
    imgTrigger: document.querySelectorAll<HTMLElement>('.img-modal'),
    txtTrigger: document.querySelectorAll<HTMLElement>('.article-button--modal'),
  },

  init(): void {
    this.handleImageClick()
  },

  handleImageClick() {
    if (this.el.imgTrigger) {
      Array.prototype.forEach.call(this.el.imgTrigger, (item) => {
        item.addEventListener('click', (e: Event & { target: HTMLAnchorElement }) => {
          e.preventDefault()
          modal.open(e.target.dataset.src, e.target.dataset.modal, e.target.dataset.name)
        })
      })
    }
  },
}

export default imgClick
