import { dictionary } from 'utility/dictionary'
import Swiper, { Autoplay, Controller, Navigation, Parallax, Pagination } from 'swiper'

Swiper.use([Autoplay, Controller, Navigation, Parallax, Pagination])

const swiper = {
  el: {
    root: document.querySelector<HTMLElement>('.hero'),

    imageSwiper: document.querySelector<HTMLElement>('.bannerSection__imageSwiper'),
    textSwiper: document.querySelector<HTMLElement>('.bannerSection__textSwiper'),

    navigationButtons: document.querySelectorAll<HTMLElement>(
      '.bannerSection__extras button'
    ),
    navigationButtonsContainer: document.querySelectorAll<HTMLElement>(
      '.bannerSection__extras'
    ),

    pagination: document.querySelector<HTMLElement>('.bannerSection__pagination'),
    //textButtons: document.querySelectorAll<HTMLElement>('.hero-block__button'),
  },

  translation: {
    slide: dictionary('genSlide'),
  },

  init(): void {
    if (this.el.imageSwiper) {
      this.initSwipers()

      //Array.prototype.forEach.call(this.el.textButtons, (button) => {
      //  button.addEventListener('mouseenter', e => this.handleMainHover(e))
      //  button.addEventListener('mouseleave', e => this.handleMainHover(e))
      //});

      Array.prototype.forEach.call(this.el.navigationButtons, (button) => {
        button.addEventListener('mouseenter', (e) => this.handleThumbHover(e))
        button.addEventListener('mouseleave', (e) => this.handleThumbHover(e))
      })
    }
  },

  initSwipers(): void {
    // const imageSwiper
    // let textSwiper

    const isSingleSlide =
      this.el.imageSwiper.querySelectorAll('.swiper-slide').length <= 1
    if (isSingleSlide) {
      this.el.root.classList.add('hero--single-slide')
      this.el.navigationButtonsContainer.forEach((x) => {
        x.classList.add('hidden')
      })
      this.el.pagination.classList.add('hidden')
    }

    const BREAKPOINTS = {
      775: {
        spaceBetween: 30,
      },
    }

    const SPACEBETWEEN = 15

    const imageSwiper = new Swiper(this.el.imageSwiper, {
      allowTouchMove: !isSingleSlide,
      autoHeight: true,
      // autoplay: isSingleSlide ? false : {
      //   delay: 4500,
      //   disableOnInteraction: true,
      // },
      lazy: true,
      observer: true,
      observeParents: true,
      loop: !isSingleSlide,
      breakpoints: BREAKPOINTS,
      grabCursor: !isSingleSlide,
      navigation: {
        nextEl: '.button--nextSlide',
        prevEl: '.button--prevSlide',
      },
      pagination: {
        bulletActiveClass: 'bannerSection__pagination-bullet--active',
        bulletClass: 'bannerSection__pagination-bullet',
        bulletElement: 'button',
        clickable: true,
        el: this.el.pagination,
        renderBullet: (index, className) =>
          `<button class="bannerSection__pagination-bullet ${className}" aria-label="${this.translation.slide} ${index}"></button>`,
        type: 'bullets',
      },
      parallax: true,
      spaceBetween: SPACEBETWEEN,
      speed: 900,
    })

    const textSwiper = new Swiper(this.el.textSwiper, {
      allowTouchMove: false,
      autoHeight: false,
      loop: !isSingleSlide,
      spaceBetween: SPACEBETWEEN,
      breakpoints: BREAKPOINTS,
      parallax: true,
    })

    imageSwiper.controller.control = textSwiper
    // textSwiper.controller.control = thumbsSwiper
  },

  handleMainHover(e: Event): void {
    if (e.type === 'mouseenter') this.el.root.classList.add('hero--main-hover')
    if (e.type === 'mouseleave') this.el.root.classList.remove('hero--main-hover')
  },

  handleThumbHover(e: Event) {
    if (e.type === 'mouseenter') this.el.root.classList.add('hero--thumb-hover')
    if (e.type === 'mouseleave') this.el.root.classList.remove('hero--thumb-hover')
  },
}

export default swiper
