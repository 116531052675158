import 'polyfills'
import { gsap, ScrollToPlugin, ScrollTrigger } from 'gsap/all'

import {
  swiper,
  // mobileSwiper,
  loader,
  scrollers,
  modal,
  header,
  mobileNav,
  imgClick,
  map,
  agreeTerms,
  // iFrameStyling,
} from 'components'

const app = {
  init(): void {
    gsap.registerPlugin(ScrollToPlugin)
    gsap.registerPlugin(ScrollTrigger)

    loader.init()
    scrollers.init()

    modal.init()
    agreeTerms.init()
    // iFrameStyling.init();
    header.init()
    mobileNav.el.root && mobileNav.init()
    swiper.el.root && swiper.init()
    imgClick.init()
    map.init()
  },
}

document.addEventListener('DOMContentLoaded', (): void => {
  setTimeout((): void => {
    document.querySelector('.body').classList.remove('preload')
  }, 100)

  app.init()
})
