import swiper from './swiper'
// import mobileSwiper from './mobileSwiper'
import loader from './loader'
import scrollers from './scrollers'
import modal from './modal'
import header from './header'
import mobileNav from './mobile-nav'
import imgClick from './img-click'
import map from './map'
import agreeTerms from './diving'
// import iFrameStyling from './iframe'

export {
  loader,
  scrollers,
  swiper,
  // mobileSwiper,
  modal,
  header,
  mobileNav,
  imgClick,
  map,
  agreeTerms,
  // iFrameStyling,
}
