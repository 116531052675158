import { MAPGL_ACCESS_TOKEN } from 'helpers'
import mapboxgl from 'mapbox-gl'

const el = {
  mapContainer: document.querySelector<HTMLElement>('.map__container'),

  toggleCloseButton: document.querySelector<HTMLElement>('.map__navigation-close'),

  toggleNavigationButton: document.querySelector<HTMLElement>('.map__navigation-toggle'),
  toggleNavigationButtonIcon: document.querySelector<HTMLElement>(
    '.map__navigation-toggle-icon'
  ),
  navigation: document.querySelector<HTMLElement>('.map__navigation'),
  navigationList: document.querySelector<HTMLElement>('.map__navigation-list'),

  placesName: document.querySelector('.map__navigation-item--places'),
  placesNavigation: document.querySelector<HTMLElement>('.map__places'),
  placesList: document.querySelector<HTMLElement>('.map__places-list'),
  placesItems: document.querySelectorAll('.map__navigation-item--coordinated'),

  staticPlaceItems: document.querySelectorAll('.map__navigation-item--static'),
}

const initialZoom: [number, number] = [-21.130772120839154, 64.25594037372109]
const currentMarkers = []
const mapBoxMarkers = []
let mapBox = null

const map = {
  init: () => {
    if (el.mapContainer) {
      if (el.placesName) {
        map.togglePlacesNavigation()
      }

      if (el.toggleNavigationButton) {
        map.toggleNavigation()
      }

      map.initMap()
      map.commonPlacesListener()

      el.staticPlaceItems.forEach((item) => {
        placeItemToggle(item)
      })
    }
  },

  togglePlacesNavigation: () => {
    el.toggleCloseButton.addEventListener('click', function () {
      if (el.toggleCloseButton) {
        if (!el.placesNavigation.classList.contains('hide')) {
          el.placesList.style.opacity = '0'
          el.placesNavigation.style.width = '0px'
          el.placesNavigation.classList.add('hide')
          el.toggleCloseButton.classList.add('hidden')
        }
      }
    })

    el.placesName.addEventListener('click', function () {
      if (el.placesNavigation) {
        if (el.placesNavigation.classList.contains('hide')) {
          el.placesNavigation.style.width = window.innerWidth <= 425 ? '240px' : '340px'
          el.placesNavigation.classList.remove('hide')
          el.toggleCloseButton.classList.remove('hidden')

          setTimeout((): void => {
            el.placesList.style.opacity = '1'
          }, 300)
        } else {
          el.placesList.style.opacity = '0'
          el.placesNavigation.style.width = '0px'
          el.placesNavigation.classList.add('hide')
          el.toggleCloseButton.classList.add('hidden')
        }
      }
    })
  },

  toggleNavigation: () => {
    el.toggleNavigationButton.addEventListener('click', function () {
      if (el.navigation) {
        if (el.navigation.classList.contains('hide')) {
          el.navigation.style.width = window.innerWidth <= 425 ? '240px' : '340px'
          el.toggleNavigationButton.style.left =
            window.innerWidth <= 425 ? '240px' : '340px'
          el.toggleNavigationButtonIcon.classList.remove('arrow--right')
          el.toggleNavigationButtonIcon.classList.add('arrow--left')
          el.navigation.classList.remove('hide')

          setTimeout((): void => {
            el.navigationList.style.opacity = '1'
          }, 300)
        } else {
          el.navigationList.style.opacity = '0'
          el.navigation.style.width = '0px'
          el.toggleNavigationButton.style.left = '0px'
          el.toggleNavigationButtonIcon.classList.remove('arrow--left')
          el.toggleNavigationButtonIcon.classList.add('arrow--right')
          el.navigation.classList.add('hide')
        }
      }
    })
  },

  initMap: () => {
    mapBox = new mapboxgl.Map({
      container: el.mapContainer,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: initialZoom,
      zoom: 18,
      accessToken: MAPGL_ACCESS_TOKEN,
    })
  },

  commonPlacesListener: () => {
    el.placesItems.forEach((item) => {
      item.addEventListener('click', function () {
        placeItemToggle(item)
      })
    })
  },
}

function placeItemToggle(item: Element) {
  const coordinatesAttr = item.getAttribute('data-coordinates')
  // const typeAttr = item.getAttribute('data-type')
  const titleAttr = item.getAttribute('data-title')
  const textAttr = item.getAttribute('data-text')
  const linkAttr = item.getAttribute('data-link')
  const imgAttr = item.getAttribute('data-img')

  if (coordinatesAttr.length > 0) {
    const multipleCoordinates = coordinatesAttr.split('/')
    const multipleTitles = titleAttr.split('/')
    const multipleText = textAttr.split('/')
    const multipleLinks = linkAttr.split('*')
    const multipleImages = imgAttr.split('*')

    multipleCoordinates.forEach((coord, i) => {
      const latLong = coord.split(',')

      const coordinates: [number, number] = [
        parseFloat(latLong[1]),
        parseFloat(latLong[0]),
      ]

      const title = multipleTitles[i]
      const text = multipleText[i]
      const link = multipleLinks[i]
      const image = multipleImages[i]

      createMarker(coordinates, item.getAttribute('data-type'), title, text, link, image)
    })

    if (currentMarkers.length >= 1) {
      const bounds = currentMarkers.reduce(function (bounds, coord) {
        return bounds.extend(coord)
      }, new mapboxgl.LngLatBounds(currentMarkers[0], currentMarkers[0]))

      mapBox.fitBounds(bounds, {
        padding: 150,
        maxZoom: 17,
      })
    }
  }
}

function createMarker(
  search: [number, number],
  type: string,
  title: string,
  text: string,
  link: string,
  img: string
) {
  let found = false
  let targetIndex = 0

  for (let i = 0; i <= currentMarkers.length - 1; i++) {
    const currMarker = currentMarkers[i]

    if (currMarker[0] === search[0] && currMarker[1] === search[1]) {
      found = true
      targetIndex = i
      break
    }
  }

  if (found) {
    currentMarkers.splice(targetIndex, 1)

    const marker = mapBoxMarkers[targetIndex]
    marker.remove()

    mapBoxMarkers.splice(targetIndex, 1)
  } else {
    currentMarkers.push(search)

    const marker = new mapboxgl.Marker(customizeMarker(type), { anchor: 'bottom' })
      .setLngLat(search)
      .setPopup(createPopup(type, title, text, link, img))
      .addTo(mapBox)

    mapBoxMarkers.push(marker)
  }
}

function customizeMarker(type: string) {
  const el = document.createElement('div')

  switch (type) {
    case 'visitor-center':
      el.className = 'marker service'
      break
    case 'parking':
      el.className = 'marker parking'
      break
    case 'wc':
      el.className = 'marker wc'
      break
    case 'camping':
      el.className = 'marker camping'
      break
    case 'fishing':
      el.className = 'marker fishing'
      break
    default:
      el.className = 'marker place'
      break
  }

  return el
}

function createPopup(
  type: string,
  title: string,
  text: string,
  link: string,
  img: string
) {
  const html = `

    <div class="map__popup">
      ${
        img
          ? `<picture>
              <source media="(min-width: 420px)" srcset="${img}?width=300&height=140">
              <img src="${img}?width=300&height=140" alt="Place image" loading="lazy">
            </picture>`
          : ''
      }
      
      <h1 class="map__popup-title">
        ${title}
      </h1>

      <p class="map__popup-text">
        ${text}
      </p>

      ${
        link
          ? `<a class="map__popup-button" href=${link} target="_blank" rel="noopener noreferrer">Nánar</a>`
          : ''
      }
    </div>`

  return new mapboxgl.Popup().setHTML(html)
}

export default map
