const mobileNav = {
  el: {
    root: document.querySelector<HTMLElement>('.mobile-nav'),
    mobileRoot: document.querySelectorAll<HTMLElement>('[data-id="mobile-root"]'),
    mobileSubRoot: document.querySelectorAll<HTMLElement>('[data-id="mobile-sub-root"]'),
    mobileBottomButtons: document.querySelector<HTMLElement>(
      '.mobile-navigation__bottom'
    ),
    // mobileSub: document.querySelectorAll<HTMLElement>('[data-id="mobile-sub"]')
  },

  init(): void {
    this.handleRootClick()
    this.handleBackToRoot()
    // this.handleActive()
  },

  handleRootClick(): void {
    if (this.el.mobileRoot) {
      this.el.mobileRoot.forEach((mobRoot) => {
        mobRoot.addEventListener('click', () => {
          const dataName = mobRoot.dataset.name
          const subnavigationItems = document.querySelectorAll(
            `[data-name="${dataName}"][data-id="mobile-sub"]`
          )
          const backToRootItem = document.querySelector(
            `[data-name="${dataName}"][data-id="mobile-sub-root"]`
          )

          // Show subnavigation items of clicked item
          subnavigationItems.forEach((x) => {
            x.classList.remove('hidden')
          })

          backToRootItem.classList.remove('hidden')

          // Hide root nav items
          this.el.mobileRoot.forEach((y) => {
            y.classList.add('hidden')
          })

          this.el.mobileBottomButtons.classList.add('hidden')
        })
      })
    }
  },

  handleBackToRoot(): void {
    if (this.el.mobileSubRoot) {
      this.el.mobileSubRoot.forEach((mobSubRoot) => {
        mobSubRoot.addEventListener('click', () => {
          const dataName = mobSubRoot.dataset.name
          const subnavigationItems = document.querySelectorAll(
            `[data-name="${dataName}"][data-id="mobile-sub"]`
          )
          const backToRootItem = document.querySelector(
            `[data-name="${dataName}"][data-id="mobile-sub-root"]`
          )

          subnavigationItems.forEach((x) => {
            x.classList.add('hidden')
          })

          backToRootItem.classList.add('hidden')

          this.el.mobileRoot.forEach((y) => {
            y.classList.remove('hidden')
          })

          this.el.mobileBottomButtons.classList.remove('hidden')
        })
      })
    }
  },

  // handleActive(): void {
  //   if (this.el.mobileSub) {
  //     this.el.mobileSub.forEach(x => {
  //       x.classList.remove('active')
  //     })
  //     this.el.mobileSub.forEach(mobSub => {
  //       mobSub.addEventListener('click', (e: Event & { target: HTMLElement }) => {
  //         debugger

  //         var navLink = mobSub.querySelector('.mobile-nav__link')
  //         navLink.classList.add('active')
  //         // classList.add('active')
  //       })
  //     })
  //   }
  // }
}

export default mobileNav
