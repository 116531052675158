const header = {
  el: {
    root: document.querySelector<HTMLElement>('.header'),
    body: document.querySelector<HTMLElement>('.body'),
    hamburger: document.querySelector<HTMLElement>('.hamburger'),
  },

  init(): void {
    // this.handleScroll()
    this.handleHamburger()
  },

  // handleScroll(): void {
  //   const modifier = 'noscroll'

  //   document.addEventListener('scroll', (): void => {
  //     if (window.pageYOffset >= 5) {
  //       this.el.body.classList.add(modifier)
  //     }
  //     else {
  //       this.el.body.classList.remove(modifier)
  //     }
  //   }, false)
  // },

  handleHamburger(): void {
    if (this.el.hamburger) {
      this.el.hamburger.addEventListener('click', () => {
        this.el.body.classList.toggle('mobile-nav-open')
        this.el.body.classList.toggle('noscroll')
      })
    }
  },
}

export default header
