import { modal } from 'components'

const agreeTerms = {
  el: {
    body: document.querySelector<HTMLElement>('.body'),

    agreeTermsDivingBox: document.querySelector<HTMLInputElement>('#agreeTermsDiving'),
    agreeTermsDivingBtn: document.querySelector<HTMLButtonElement>(
      '.article-button--agreeTermsDiving'
    ),
    agreeTermsDivingBtnAnchor: document.getElementById(
      'article-button--agreeTermsDivingAnchor'
    ),

    articleButtonModal: document.querySelector<HTMLElement>('.article-button--modal'),
    agreeTermsDivingModal: document.querySelector<HTMLElement>(
      '.modal--agreeTermsDiving'
    ),

    // divingBookingFrame: document.querySelector('#bokunWidgetw11715_9161e6f1_b14d_46dd_8b9e_13355fc6ffe5'),
  },

  init(): void {
    this.handleCheckBox()
    this.handleBtn()
    this.handleModal()
    this.handleForm()
  },

  handleCheckBox() {
    if (this.el.agreeTermsDivingBox) {
      this.el.agreeTermsDivingBox.addEventListener('change', () => {
        if (this.el.agreeTermsDivingBox.checked) {
          this.el.agreeTermsDivingBtnAnchor.classList.remove('button--disabled')
        } else {
          this.el.agreeTermsDivingBtnAnchor.classList.add('button--disabled')
        }
      })
    }
  },

  handleBtn() {
    if (this.el.agreeTermsDivingBtn) {
      this.el.agreeTermsDivingBtn.addEventListener('click', () => {
        if (this.el.agreeTermsDivingBox.checked) {
          modal.close('diving')
          localStorage.setItem('agreeDivingTerms', 'true')

          // this.el.divingBookingFrame.classList.remove('hidden')
        }
      })
    }
  },

  handleModal() {
    if (this.el.agreeTermsDivingModal) {
      // console.log("here")
      this.el.body.classList.add('noscroll')

      if (
        localStorage.getItem('agreeDivingTerms') &&
        localStorage.getItem('agreeDivingTerms') == 'true'
      ) {
        modal.close('diving')
        // this.el.divingBookingFrame.classList.remove('hidden')
      }
    }
  },

  handleForm() {
    window.addEventListener('storage', () => {
      if (!localStorage.getItem('agreeDivingTerms')) {
        // this.el.divingBookingFrame.classList.add('hidden')

        this.el.agreeTermsDivingModal.classList.add('open')
        this.el.agreeTermsDivingModal.classList.add(`modal--agreeTermsDiving`)
        this.el.agreeTermsDivingModal.classList.add('fade-in')
        this.el.agreeTermsDivingModal.classList.add('noscroll')

        this.el.agreeTermsDivingBox.checked = false
      }
    })

    // window.addEventListener('storage', (event) => {
    //   console.log("here");
    //   if (!localStorage.getItem('agreeDivingTerms')) {
    //     this.el.divingBookingFrame.classList.add('hidden')

    //     this.el.agreeTermsDivingModal.classList.add('open')
    //     this.el.agreeTermsDivingModal.classList.add(`modal--agreeTermsDiving`)
    //     this.el.agreeTermsDivingModal.classList.add("fade-in")
    //     this.el.agreeTermsDivingModal.classList.add('noscroll')

    //     this.el.agreeTermsDivingBox.checked = false;
    //   }
    // });
  },
}

export default agreeTerms
