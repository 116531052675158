const modal = {
  el: {
    root: document.querySelector<HTMLElement>('.modal'),
    close: document.querySelector<HTMLButtonElement>('.modal__close'),
    box: document.querySelector<HTMLElement>('.modal__box'),
    background: document.querySelector<HTMLElement>('.modal__background'),
    img: document.querySelector<HTMLElement>('.modal__img'),
    body: document.querySelector<HTMLElement>('.body'),
  },

  init(): void {
    this.handleCloseButton()
  },

  open(url = '', type = '', name = ''): void {
    this.el.root.classList.add('open')
    this.el.root.classList.add(`modal--${type}`)
    this.el.root.classList.add('fade-in')
    this.el.body.classList.add('noscroll')
    this.el.close.setAttribute('data-modal', type)
    this.el.close.focus() // TODO: figure out why this doesent fire when you open with keyboard

    if (url) {
      if (type === 'image') {
        const img = document.createElement('img')
        img.classList.add('modal__img')
        img.setAttribute('src', url)
        img.style.opacity = '1'

        this.el.box.appendChild(img)
      } else if (type === 'stream') {
        const stream = document.createElement('iframe')
        stream.setAttribute('allowfullscreen', '')
        stream.setAttribute('frameborder', '0')

        stream.classList.add('modal__img')
        stream.setAttribute('src', url)
        stream.style.opacity = '1'

        this.el.box.appendChild(stream)

        this.el.box.classList.add('stream')
        this.el.background.classList.add('stream')
      }
      // this.handleYouTube(url)
      // this.el.img.setAttribute('src', url)
      // this.el.img.style.opacity = '1'

      document.querySelector<HTMLElement>('.modal__heading').innerText = name
    }
  },

  close(type = ''): void {
    if (type !== 'diving') {
      const img = this.el.box.querySelector('.modal__img')
      this.el.box.removeChild(img)
    }

    if (type === 'stream') {
      this.el.box.classList.remove('stream')
      this.el.background.classList.remove('stream')
    }

    this.el.root.classList.remove('open')
    this.el.root.classList.remove(`modal--${type}`)
    this.el.root.classList.remove('fade-in')
    this.el.body.classList.remove('noscroll')

    // this.reset()
  },

  // reset() {
  //   this.el.img.setAttribute('src', '')
  // },

  //== Handlers

  handleCloseButton(): void {
    if (this.el.close) {
      this.el.close.addEventListener(
        'click',
        (e: Event & { target: HTMLButtonElement }) => {
          this.close(e.target.dataset.modal)
        }
      )
    }
  },

  // handleYouTube(url: string): void {
  //   // console.log('handling youtube...');

  //   const youtube = 'https://www.youtube.com/embed/'
  //   const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/
  //   const youtubeMobile = /(http:|https:)?\/\/(m\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/
  //   const youtubePlaylist = /(http:|https:)?\/\/(?:www\.)?youtube\.com\/watch\?(?:&.*)*((?:v=([a-zA-Z0-9_\-]{11})(?:&.*)*&list=([a-zA-Z0-9_\-]{18}))|(?:list=([a-zA-Z0-9_\-]{18})(?:&.*)*&v=([a-zA-Z0-9_\-]{11})))(?:&.*)*(?:\#.*)*$/

  //   if (url) {
  //     const matchPlaylist = url.match(youtubePlaylist)
  //     const match = url.match(youtubeRegex)
  //     const matchMobile = url.match(youtubeMobile)
  //     let id
  //     let listID

  //     const params = '?enablejsapi=1'

  //     if (matchPlaylist) {
  //       id = matchPlaylist[3]
  //       listID = matchPlaylist[4]
  //       this.el.img.setAttribute('src', `${youtube}${id}?list=${listID}${params}`)
  //     }
  //     else if (match) {
  //       id = match[6];
  //       this.el.img.setAttribute('src', `${youtube}${id}${params}`)
  //     }
  //     else if (matchMobile) {
  //       id = matchMobile[6];
  //       this.el.img.setAttribute('src', `${youtube}${id}${params}`)
  //     }

  //     this.el.img.style.opacity = '1'
  //   }
  // },
}

export default modal
