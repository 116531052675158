import gsap from 'gsap'

const el = {
  layout: document.querySelector('.layout'),
  yellowCover: document.querySelector('.loader__cover--yellow'),
  primaryCover: document.querySelector('.loader__cover--primary'),
  logo: document.querySelector('.loader svg'),
}

const loader = {
  init: () => {
    if (sessionStorage.getItem('pageLoaded')) {
      loader.betweenPages()
    } else {
      loader.hideCoverOnFirstPageLoad()
    }

    sessionStorage.setItem('pageLoaded', 'true')

    // handling page restore for mobile iOS - without this back/forward buttons do not hide loader covers
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        window.location.reload()
      }
    })
  },

  betweenPages: () => {
    gsap.to(el.yellowCover, {
      delay: 0.4,
      duration: 0.4,
      ease: 'power4.inOut',
      scaleY: 0,
      onStart: () => {
        el.primaryCover.classList.add('loader__cover--hidden')
      },
    })
  },

  hideCover: () => {
    gsap.to(el.yellowCover, {
      duration: 0.5,
      ease: 'power4.out',
      scaleY: 0,
      transformOrigin: '0 0',
      onStart: () => {
        el.primaryCover.classList.add('loader__cover--hidden')
      },
    })

    gsap.fromTo(
      el.layout,
      {
        y: 0,
      },
      {
        clearProps: 'all',
        duration: 1,
        ease: 'power4.out',
        y: 0,
      }
    )
  },

  hideCoverOnFirstPageLoad: () => {
    gsap.to(el.yellowCover, {
      delay: 0.5,
      duration: 0.5,
      ease: 'power4.inOut',
      scaleY: 0,
      onStart: () => {
        gsap.fromTo(
          el.logo,
          {
            y: -25,
          },
          {
            duration: 1,
            ease: 'none',
            y: 0,
          }
        )
      },
      onComplete: () => {
        gsap.to(el.yellowCover, {
          duration: 0.5,
          ease: 'power4.in',
          scaleY: 1,
          transformOrigin: '0 100%',
          onComplete: loader.hideCover,
        })
      },
    })
  },

  showCover: ({ redirectUrl, delay = 0 }) => {
    gsap.set('a', { pointerEvents: 'none' })
    gsap.fromTo(
      el.yellowCover,
      {
        scaleY: 0,
        transformOrigin: '0 100%',
      },
      {
        delay,
        duration: 0.5,
        ease: 'power4.in',
        scaleY: 1,
        onComplete: () => {
          window.location.href = redirectUrl
        },
      }
    )
  },
}

export default loader
