import { gsap, ScrollTrigger } from 'gsap/all'
import { isProduction } from '../utility/helpers'

/*
  start: string = 'top 80%', // enter and leaveback
  end: string = 'top bottom', // leave and enterback
*/

interface IScrollOptions {
  animation?: any
  enter?: Function
  enterBack?: Function
  leave?: Function
  leaveBack?: Function
  start?: string
  end?: string
  scrub?: boolean
}

interface ICreateTriggers {
  targets?: HTMLElement[]
  removeClasses?: boolean
  hasScrub?: boolean
}

const scrollers = {
  el: {
    //storeProducts: document.querySelectorAll<HTMLElement>('.product-list-scroller'),
    // sectionHeading: document.querySelectorAll<HTMLElement>('.section-heading'),

    overviewBlock: document.querySelectorAll<HTMLElement>('.overview'),

    eventsBlock: document.querySelectorAll<HTMLElement>('.events'),

    featuredBlockRight: document.querySelectorAll<HTMLElement>('.featured-card.right'),
    featuredBlockLeft: document.querySelectorAll<HTMLElement>('.featured-card.left'),
    heroTop: document.querySelectorAll<HTMLElement>('.topSection'),
    heroBanner: document.querySelectorAll<HTMLElement>('.bannerSection'),
    heroBottom: document.querySelectorAll<HTMLElement>('.bottomSection'),

    navFeatured: document.querySelectorAll<HTMLElement>('.navFeatured'),

    newsBlock: document.querySelectorAll<HTMLElement>('.news'),
    newsCategory: document.querySelectorAll<HTMLElement>('.news-category'),
  },

  classes: {
    reveal: {
      root: 'text-reveal',
      ltr: 'text-reveal--ltr-animation',
      ltt: 'text-reveal--ltt-animation',
    },
  },

  settings: {
    markers: {
      startColor: 'red',
      endColor: 'green',
      fontSize: '18px',
      fontWeight: 'bold',
      indent: 20,
    },
  },

  init(): void {
    // if (this.el.sectionHeading) {
    //   this.sectionHeading()
    // }

    if (this.el.overviewBlock) {
      this.overviewBlock()
    }

    if (this.el.eventsBlock) {
      this.eventsBlock()
    }

    if (this.el.newsBlock) {
      this.newsBlock()
    }

    if (this.el.featuredBlockRight) {
      this.featuredBlock()
    }

    // if (this.el.featuredBlockLeft) {
    //   this.featuredBlockLeft()
    // }

    if (this.el.heroTop) {
      this.heroTop()
    }

    if (this.el.heroBanner) {
      this.heroBanner()
    }

    if (this.el.heroBottom) {
      this.heroBottom()
    }

    if (this.el.navFeatured) {
      this.navFeatured()
    }

    // if (this.el.newsCategory) {
    //  this.newsCategory()
    // }
  },

  // Helpers
  addClass(target: HTMLElement, type = 'ltr'): void {
    if (target) {
      target.classList.add(
        type !== 'ltr' ? this.classes.reveal.ltt : this.classes.reveal.ltr
      )
    }
  },

  setOptions(
    trigger: HTMLElement,
    {
      animation,
      enter,
      enterBack,
      leave,
      leaveBack,
      start = 'top 80%',
      end = 'top bottom',
      scrub = false,
    }: IScrollOptions,

    type = 'default'
  ) {
    return {
      trigger: trigger,
      animation: animation,
      start: type === 'return' ? 'top 100%' : start, // enter and leaveback
      end: end, // leave and enterback
      markers: !isProduction ? this.settings.markers : false,
      scrub: scrub,

      onEnter: () => enter && enter(),
      onLeaveBack: () => leaveBack && leaveBack(),
      onLeave: () => leave && leave(),
      onEnterBack: () => enterBack && enterBack(),
    }
  },

  createTriggers(
    trigger: HTMLElement,
    animation: any,
    { targets = [], removeClasses = false, hasScrub = false }: ICreateTriggers = undefined
  ): void {
    ScrollTrigger.create(
      this.setOptions(trigger, {
        enter: () => {
          animation.play()
        },
        scrub: hasScrub,
      })
    )

    ScrollTrigger.create(
      this.setOptions(
        trigger,
        {
          leaveBack: () => {
            animation.progress(0).pause()

            if (removeClasses && targets !== undefined) {
              Array.prototype.forEach.call(targets, (item) => {
                if (item) {
                  if (item.classList.contains('text-reveal--ltt')) {
                    item.classList.remove(this.classes.reveal.ltt)
                  } else {
                    item.classList.remove(this.classes.reveal.ltr)
                  }
                }
              })
            }
          },
        },
        'return'
      )
    )
  },

  setDefaultTransition({
    sections,
    items = '',
    stagger,
    delay = 0,
    direction = '',
    value = 200,
  }: {
    sections: NodeListOf<HTMLElement>
    items: string
    stagger?: any
    delay?: number
    direction: string
    value: number
  }): void {
    Array.prototype.forEach.call(sections, (section) => {
      const animation = gsap.timeline({ paused: true })
      const elements = section.querySelectorAll(items)

      gsap.set(section, { opacity: 0 })

      const config = {
        clearProps: 'all',
        duration: 1.5,
        ease: 'power4.out',
        stagger: stagger,
        opacity: 1,
        delay: delay,
        x: 0,
        y: 0,
      }

      // if (stagger) config['stagger'] = stagger
      // if (delay) config['delay'] = delay

      if (direction == 'y') {
        animation
          .set(section, { clearProps: 'all' }, 0)
          .set(elements, { opacity: 0, y: value }, 0)
          .to(elements, config, 0)
      } else {
        animation
          .set(section, { clearProps: 'all' }, 0)
          .set(elements, { opacity: 0, x: value }, 0)
          .to(elements, config, 0)
      }

      this.createTriggers(section, animation, { targets: elements, removeClasses: true })
    })
  },

  setParallax({
    sections,
    scaleFrom,
    scaleTo,
  }: {
    sections: NodeListOf<HTMLElement>
    scaleFrom: number
    scaleTo: number
  }): void {
    Array.prototype.forEach.call(sections, (section) => {
      const elements = section.querySelectorAll('img:not(.no-prlx)')

      gsap.fromTo(
        elements,
        {
          scale: scaleFrom,
        },
        {
          duration: 1,
          ease: 'none',
          scale: scaleTo,
          scrollTrigger: {
            end: 'bottom 0%',
            scrub: 1,
            start: 'top 50%',
            toggleActions: 'play none resume reverse',
            trigger: sections,
          },
        }
      )
    })
  },

  // Overview block content
  overviewBlock(): void {
    this.setDefaultTransition({
      sections: this.el.overviewBlock,
      items: '.overview-card',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })
  },

  // Events block content
  eventsBlock(): void {
    this.setDefaultTransition({
      sections: this.el.eventsBlock,
      items: '.events__content',
      stagger: 0,
      direction: 'y',
      value: 100,
    })
  },

  // News block content
  newsBlock(): void {
    this.setDefaultTransition({
      sections: this.el.newsBlock,
      items: '.news-card',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.newsCategory,
      items: '.news-card',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })
  },

  // Featured block content when text is on left
  featuredBlock(): void {
    const desktop = window.innerWidth >= 1200

    if (desktop) {
      this.setDefaultTransition({
        sections: this.el.featuredBlockLeft,
        items: 'picture',
        stagger: 0.1,
        direction: 'x',
        value: -200,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockLeft,
        items: '.featured-card__content',
        stagger: 0.1,
        direction: 'x',
        value: 200,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockRight,
        items: 'picture',
        stagger: 0,
        direction: 'x',
        value: 200,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockRight,
        items: '.featured-card__content',
        stagger: 0.1,
        direction: 'x',
        value: -200,
      })
    } else {
      this.setDefaultTransition({
        sections: this.el.featuredBlockLeft,
        items: 'picture',
        stagger: 0,
        direction: 'y',
        value: 100,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockLeft,
        items: '.featured-card__content',
        stagger: 0,
        direction: 'y',
        value: 100,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockRight,
        items: 'picture',
        stagger: 0,
        direction: 'y',
        value: 100,
      })

      this.setDefaultTransition({
        sections: this.el.featuredBlockRight,
        items: '.featured-card__content',
        stagger: 0,
        direction: 'y',
        value: 100,
      })
    }
  },

  // Nav featured blocks
  navFeatured(): void {
    this.setDefaultTransition({
      sections: this.el.navFeatured,
      items: '.navFeatured-card',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })
  },

  // Top section in hero block
  heroTop(): void {
    this.setDefaultTransition({
      sections: this.el.heroTop,
      items: '.notification-container, .button-container',
      stagger: 0,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroTop,
      items: '.topSection-title',
      stagger: 0,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroTop,
      items: '.topSection-text',
      stagger: 0,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroTop,
      items: '.topSection-subtitle',
      stagger: 0,
      direction: 'y',
      value: 100,
    })
  },

  // Banner section in hero block
  heroBanner(): void {
    this.setDefaultTransition({
      sections: this.el.heroBanner,
      items: 'img',
      stagger: 0,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroBanner,
      items: '.bannerSection-description',
      stagger: 0,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroBanner,
      items: '.button-container',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })

    // Parallax

    this.setParallax({
      sections: this.el.heroBanner,
      scaleFrom: 1,
      scaleTo: 1.08,
    })
  },

  // Bottom section in hero block
  heroBottom(): void {
    this.setDefaultTransition({
      sections: this.el.heroBottom,
      items: '.activityCard',
      stagger: 0.1,
      direction: 'y',
      value: 100,
    })

    this.setDefaultTransition({
      sections: this.el.heroBottom,
      items: '.bottomSection-text',
      stagger: 0,
      direction: 'y',
      value: 100,
    })
  },
}

export default scrollers
